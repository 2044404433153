import "./ProjectModal.scss";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/all";

const ProjectModal = ({ isVisible, project, onClose, accentColor }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const overlayRef = useRef(null);
  const modalTimelineRef = useRef(null);

  useEffect(() => {
    if (isVisible) setShouldRender(true);
  }, [isVisible]);

  useGSAP(() => {
    if (isVisible) {
      setShouldRender(true);
      openModalAnimation();
    } else {
      closeModalAnimation();
    }
  }, [isVisible]);

  useGSAP(() => {
    if (shouldRender && isVisible) {
      // Ensure all elements are rendered
      setTimeout(() => {
        openModalAnimation();
      }, 5);
    }
  }, [shouldRender, isVisible]);

  const openModalAnimation = () => {
    if (!overlayRef.current) return;
    let modalTl = gsap.timeline();
    modalTimelineRef.current = modalTl;

    let descriptionST = new SplitText(".modal-description", {
      type: "lines",
    });

    modalTl
      .to(overlayRef.current, { opacity: 1, duration: 0.25, ease: "expo" })
      .from(
        ".modal-content",
        {
          rotationX: 90,
          duration: 1.25,
          ease: "expo",
        },
        0.25
      )
      .from(
        ".modal-header",
        {
          delay: 0.5,
          opacity: 0,
          rotationX: 90,
          y: 100,
          ease: "expo",
        },
        0.5
      )
      .from(
        ".modal-title",
        {
          delay: 0.5,
          opacity: 0,
          rotationX: -90,
          y: -45,
          ease: "expo",
          duration: 1.15,
        },
        0.5
      )
      .from(
        descriptionST.lines,
        {
          delay: 0.5,
          opacity: 0,
          rotationX: 90,
          y: 45,
          ease: "expo",
          duration: 1.15,
        },
        0.5
      )
      .from(
        ".links-container",
        {
          delay: 0.5,
          opacity: 0,
          x: -75,
          ease: "expo.out",
          duration: 1.5,
          stagger: {
            each: 0.25,
          },
        },
        0.5
      );
  };

  const closeModalAnimation = () => {
    if (!overlayRef.current) return;

    if (modalTimelineRef.current) {
      modalTimelineRef.current
        .reverse()
        .eventCallback("onReverseComplete", () => {
          setShouldRender(false);
        });
    } else {
      setShouldRender(false);
    }
  };

  if (!shouldRender) return null;

  return (
    <div className="modal-overlay" ref={overlayRef} onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <header className="modal-header">
          <div className="invisible-element"></div>
          <button className="close-button" onClick={onClose}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </header>
        <div className="modal-body">
          <p className="modal-title">{project.project_name}</p>
          <div className="links-container">
            <Link
              className="modal-github"
              to={project.github}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p style={{ color: accentColor }}>view code on github</p>
            </Link>
            {project.website && (
              <Link
                className="modal-website"
                to={project.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p style={{ color: accentColor }}>visit website</p>
              </Link>
            )}
          </div>
          <div className="modal-description">
            <h3>project overview:</h3>
            <p>{project.description}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectModal;
