import "./Header.scss";
import { Link } from "react-router-dom";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Header = ({ color, shadow, background }) => {
  useGSAP(() => {
    let headerTl = gsap.timeline({ delay: 0.5 });

    headerTl.from(
      ".header-menu-container span",
      {
        y: -50,
        rotationX: 45,
        opacity: 0,
        duration: 3,
        ease: "expo",
        stagger: -0.5,
      },
      0.5
    );
  }, []);

  return (
    <div className="header" style={{ background: background }}>
      <header className="header-content">
        <div className="header-menu-container">
          <span>
            <Link
              className="nav-link"
              to="/"
              style={{ color: color, textShadow: shadow }}
            >
              home
            </Link>
          </span>

          <span>
            <Link
              className="nav-link"
              to="/about"
              style={{ color: color, textShadow: shadow }}
            >
              about
            </Link>
          </span>

          <span>
            <Link
              className="nav-link"
              to="/projects"
              style={{ color: color, textShadow: shadow }}
            >
              projects
            </Link>
          </span>
          <span>
            <Link
              className="nav-link"
              to="/contact"
              style={{ color: color, textShadow: shadow }}
            >
              contact
            </Link>
          </span>
        </div>
      </header>
    </div>
  );
};

export default Header;
