import "./Contact.scss";
import { useEffect, useState } from "react";

// component imports
import Header from "../../components/Header/Header";
import handleResize from "../../helpers/windowResize";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/all";

const Contact = () => {
  // resize window event listener for mobile responsiveness
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // animations
  useGSAP(() => {
    const titleText = new SplitText(".contact-title", {
      type: "chars",
      charsClass: "char",
    });

    let tl = gsap.timeline();
    tl.from(".circles circle", {
      delay: 0.75,
      y: 800,
      ease: "expo",
      duration: 3,
      stagger: {
        each: -0.15,
      },
    })
      .set(".circles circle", {
        stagger: {
          each: 0.15,
        },
      })
      .from(
        ".circles circle",
        {
          opacity: 0,
          scale: 0.85,
          ease: "back(4)",
          duration: 0.47,
          repeat: -1,
          stagger: {
            each: -0.06,
            repeat: -1,
            yoyo: true,
          },
          transformOrigin: "center center",
        },
        0.75
      )
      .from(
        titleText.chars,
        {
          y: "random(-50 50)",
          opacity: 0,
          duration: 1,
          stagger: 0.1,
        },
        1
      )

      .from(
        ".contact-links span",
        {
          rotationX: 45,
          opacity: 0,
          duration: 3,
          ease: "expo",
          stagger: -0.5,
          transformOrigin: "center center -50px",
        },
        1.2
      );
  });

  return (
    <>
      <Header color="var(--accent-white)" background="transparent" shadow=".75px .75px 2.25px rgba(0, 0, 0, 0.95)" />

      <div className="contact-container">
        <svg
          className="contact-animation"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 716 1008"
        >
          <g fill="none" fillRule="evenodd">
            <g className="circles" transform="translate(0 292)">
              <circle cx="358" cy="358" r="410" />
              <circle cx="358" cy="358" r="375" />
              <circle cx="358" cy="358" r="335" />
              <circle cx="358" cy="358" r="295" />
              <circle cx="358" cy="358" r="258" />
              <circle cx="358" cy="358" r="220" />
              <circle cx="358" cy="358" r="187" />
              <circle cx="358" cy="358" r="159" />
              <circle cx="358" cy="358" r="135" />
              <circle cx="358" cy="358" r="116" />
              <circle cx="358" cy="358" r="100" />
            </g>
          </g>
        </svg>

        <div className="contact-content">
          <div className="title-wrap">
            <h1 className="contact-title">contact</h1>
          </div>
          <div className="links-wrap">
            <div className="contact-links">
              <span>
                <a
                  href="https://github.com/KevBarbour15"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  github
                </a>
              </span>
              <span>
                <a
                  href="https://www.linkedin.com/in/kevin-barbour-70914b298/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  linkedin
                </a>
              </span>
              <span>
                <a href="mailto:kevintbarbour@gmail.com">email</a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
