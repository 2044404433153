import { Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing/Landing.jsx";
import About from "./pages/About/About.jsx";
import Projects from "./pages/Projects/Projects.jsx";
import Contact2 from "./pages/Contact/Contact.jsx";

//GSAP animation imports
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrambleTextPlugin from "gsap/ScrambleTextPlugin";
import SplitText from "gsap/SplitText";
import CustomEase from "gsap/CustomEase";

gsap.registerPlugin(ScrollTrigger, ScrambleTextPlugin, SplitText, CustomEase);

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contact" element={<Contact2 />} />
      </Routes>
    </div>
  );
}

export default App;
