import "./AboutModal.scss";
import { useRef, useEffect, useState } from "react";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { SplitText } from "gsap/all";

const AboutModal = ({ isVisible, onClose }) => {
  const [shouldRender, setShouldRender] = useState(false);
  const overlayRef = useRef(null);
  const modalTimelineRef = useRef(null);

  useEffect(() => {
    if (isVisible) setShouldRender(true);
  }, [isVisible]);

  useGSAP(() => {
    if (isVisible) {
      setShouldRender(true);
      openModalAnimation();
    } else {
      closeModalAnimation();
    }
  }, [isVisible]);

  useGSAP(() => {
    if (shouldRender && isVisible) {
      // Ensure all elements are rendered with delay
      setTimeout(() => {
        openModalAnimation();
      }, 5);
    }
  }, [shouldRender, isVisible]);

  const openModalAnimation = () => {
    if (!overlayRef.current) return;
    let modalTl = gsap.timeline();
    modalTimelineRef.current = modalTl;

    let descriptionST = new SplitText(".about-modal-body p", {
      type: "lines",
    });

    modalTl
      .to(overlayRef.current, { opacity: 1, duration: 0.25, ease: "expo" })
      .from(
        ".about-modal-content",
        {
          rotationX: 90,
          delay: 0.25,
          duration: 1.25,
          ease: "expo",
        },
        0.15
      )
      .from(
        ".about-modal-header",
        {
          delay: 0.35,
          opacity: 0,
          rotationX: 45,
          y: -45,
          ease: "expo",
        },
        0.5
      )
      .from(
        descriptionST.lines,
        {
          delay: 0.5,
          opacity: 0,
          rotationX: 90,
          y: 45,
          ease: "expo",
        },
        0.75
      );
  };

  const closeModalAnimation = () => {
    if (!overlayRef.current) return;

    if (modalTimelineRef.current) {
      modalTimelineRef.current.reverse().eventCallback("onReverseComplete", () => {
        setShouldRender(false);
      });
    } else {
      setShouldRender(false);
    }
  };

  if (!shouldRender) return null;

  return (
    <div className="about-modal-overlay" ref={overlayRef} onClick={onClose}>
      <div className="about-modal-content" onClick={(e) => e.stopPropagation()}>
        <header className="about-modal-header">
          <div className="invisible-element"></div>
          <button className="about-close-button" onClick={onClose}>
            <span className="material-symbols-outlined">close</span>
          </button>
        </header>
        <div className="about-modal-body">
          <p> hi, i'm kevin barbour.</p>
          <p>
            I'm a full-stack developer based in Brooklyn, New York. I love the
            process of turning ideas into visually appealing, tangible, working
            applications that positively impact and enrich lives.
          </p>
          <p>
            I'm eager to connect with individuals and organizations that share a
            vision for innovative, impactful projects and a care for people. If
            you have an idea you're passionate about bringing to life, let's
            connect.
          </p>
          <p>
            when i'm not designing and building applications, I find balance and
            inspiration in playing pool and basketball, exploring my new home of
            New York City with my amazing partner, and unwaveringly supporting
            my beloved Denver Nuggets. Each of these drive my passion for
            growth, teamwork, community, and people.
          </p>
        </div>
      </div>
    </div>
  );
};

export default AboutModal;