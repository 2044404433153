import { useState, useEffect } from "react";
import "./Projects.scss";
import projectsData from "../../projects.json";

// component imports
import Header from "../../components/Header/Header";
import ProjectModal from "../../components/ProjectModal/ProjectModal";
import handleResize from "../../helpers/windowResize";

// animation imports
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

const Projects = () => {
  // set modal state
  const [isModalOpen, setModalOpen] = useState(false);

  // set project state
  const [project, setProject] = useState("");

  // open modal function
  const handleOpenModal = (idx) => {
    setProject(projectsData.projects[idx]);
    setModalOpen(true);
  };

  // resize window event listener for mobile responsiveness
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useGSAP(() => {
    let tl = gsap.timeline({ delay: 0.5 });

    // set up the cubes
    gsap.set(".cuboid", {
      transformPerspective: 800,
      transformOrigin: "left center",
    });

    tl.to(".base", {
      scaleX: 0,
      duration: 3,
      ease: "power4.inOut",
      transformOrigin: "right center",
      stagger: 0.1,
    }).from(
      ".face--front .word",
      {
        x: "70%",
        duration: 3,
        ease: "power4",
        stagger: 0.15,
      },
      0
    );

    let targets = gsap.utils.toArray(".info");

    targets.forEach(function (item, i) {
      let bg = item.querySelectorAll(".project-line__bg");
      let txt = item.querySelectorAll(".project-line__txt");

      let ttl = gsap.timeline({
        defaults: {
          duration: 1,
          stagger: 0.15,
        },
      });

      ttl
        .from(bg, {
          scaleX: 0,
          ease: "power2.in",
          transformOrigin: "left top",
        })
        .set(txt, {
          opacity: 1,
        })
        .to(bg, {
          scaleX: 0,
          ease: "power2",
          transformOrigin: "right top",
        });

      tl.add(ttl, i / 8);
    });

    let newTl = gsap.timeline({
      delay: 3.25,
      repeat: -1,
      repeatDelay: 1,
      defaults: {
        stagger: 0.25,
        duration: (i) => 2 + i * 0.4,
      },
    });
    newTl
      .to(".cuboid", {
        rotateX: -180,
        ease: "power2.inOut",
      })
      .set(".face--front .word", { y: 0 })
      .from(
        ".face--back .word",
        {
          y: "-100%",
          duration: (i) => 5 + i * 0.2,
          ease: "power2",
          immediateRender: false,
        },
        0
      )
      .to(
        ".cuboid",
        {
          rotateX: -360,
          ease: "power2.inOut",
        },
        8
      )
      .fromTo(
        ".face--front .word",
        {
          y: "-100%",
        },
        {
          y: "0%",
          duration: (i) => 5 + i * 0.2,
          ease: "power2",
          immediateRender: false,
        },
        8
      );

    let swellyScramble = gsap
      .timeline({
        repeat: -1,
        repeatDelay: 10.5,
        delay: 3.25,
        defaults: { ease: "none" },
      })
      .to("#scramble_1", {
        duration: 0.75,
        scrambleText: {
          text: "click to learn more",
          chars: "lowerCase",
          rightToLeft: false,
          tweenLength: false,
          newClass: "scramble",
          revealDelay: 0.5,
        },
      })
      .to(
        "#scramble_1",
        {
          duration: 0.75,
          scrambleText: {
            text: "swelly studio",
            chars: "lowerCase",
            rightToLeft: true,
            tweenLength: false,
            revealDelay: 0.5,
          },
        },
        2
      );

    let doboScramble = gsap
      .timeline({
        repeat: -1,
        repeatDelay: 10.5,
        delay: 6.75,
        defaults: { ease: "none" },
      })
      .to("#scramble_2", {
        duration: 0.75,
        scrambleText: {
          text: "click to learn more",
          chars: "lowerCase",
          rightToLeft: false,
          tweenLength: false,
          newClass: "scramble",
          revealDelay: 0.5,
        },
      })
      .to(
        "#scramble_2",
        {
          duration: 0.75,
          scrambleText: {
            text: "dobo nyc",
            chars: "lowerCase",
            rightToLeft: true,
            tweenLength: false,
            revealDelay: 0.5,
          },
        },
        2
      );

    let resumeScramble = gsap
      .timeline({
        repeat: -1,
        repeatDelay: 10.5,
        delay: 10.25,
        defaults: { ease: "none" },
      })
      .to("#scramble_3", {
        duration: 0.75,
        scrambleText: {
          text: "click to learn more",
          chars: "lowerCase",
          rightToLeft: false,
          tweenLength: false,
          newClass: "scramble",
          revealDelay: 0.5,
        },
      })
      .to(
        "#scramble_3",
        {
          duration: 0.75,
          scrambleText: {
            text: "resume therapy",
            chars: "lowerCase",
            rightToLeft: true,
            tweenLength: false,
            revealDelay: 0.5,
          },
        },
        2
      );

    let capturingScramble = gsap
      .timeline({
        repeat: -1,
        repeatDelay: 10.5,
        delay: 13.75,
        defaults: { ease: "none" },
      })
      .to("#scramble_4", {
        duration: 0.75,
        scrambleText: {
          text: "click to learn more",
          chars: "lowerCase",
          rightToLeft: false,
          tweenLength: false,
          newClass: "scramble",
          revealDelay: 0.5,
        },
      })
      .to(
        "#scramble_4",
        {
          duration: 0.75,
          scrambleText: {
            text: "analyzing semantic variance",
            chars: "lowerCase",
            rightToLeft: true,
            tweenLength: false,
            revealDelay: 0.5,
          },
        },
        2
      );
  });

  return (
    <>
      <Header
        color="var(--accent-white)"
        background="transparent"
        shadow=".75px .75px 2.25px rgba(0, 0, 0, 0.95)"
      />
      <div className="projects-container">
        <div className="projects">
          <div className="line"></div>
          <div className="bar bar--1">
            <div className="cuboid cuboid--1">
              <div className="face face--front">
                <h1 className="word">projects</h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  projects
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--2">
            <div className="cuboid cuboid--2">
              <div className="face face--front">
                <h1 className="word">projects</h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  projects
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--3">
            <div className="cuboid cuboid--3">
              <div className="face face--front">
                <h1 className="word">projects</h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  projects
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--4">
            <div className="cuboid cuboid--4">
              <div className="face face--front">
                <h1 className="word">projects</h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  projects
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--5">
            <div className="cuboid cuboid--5">
              <div className="face face--front">
                <h1 className="word">projects</h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  projects
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
            </div>
          </div>
          <div className="bar bar--6">
            <div className="cuboid cuboid--6">
              <div className="face face--front">
                <h1 className="word">projects</h1>
                <div className="base"></div>
              </div>
              <div className="face face--back">
                <h1 className="word" aria-hidden="true">
                  projects
                </h1>
              </div>
              <div className="face face--top">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
              <div className="face face--bottom">
                <h1 className="word" aria-hidden="true">
                  kevin's
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="swelly info">
          <span className="project-line">
            <button
              onClick={() => handleOpenModal(1)}
              className="project-line__txt"
              id="scramble_1"
            >
              swelly studio
            </button>
            <span className="project-line__bg"></span>
          </span>
        </div>
        <div className="dobo info">
          <span className="project-line">
            <button
              onClick={() => handleOpenModal(2)}
              className="project-line__txt"
              id="scramble_2"
            >
              dobo nyc
            </button>
            <span className="project-line__bg"></span>
          </span>
        </div>
        <div className="resume info">
          <span className="project-line">
            <button
              onClick={() => handleOpenModal(3)}
              className="project-line__txt"
              id="scramble_3"
            >
              resume therapy
            </button>
            <span className="project-line__bg"></span>
          </span>
        </div>
        <div className="capturing info">
          <span className="project-line">
            <button
              onClick={() => handleOpenModal(0)}
              className="project-line__txt"
              id="scramble_4"
            >
              analyzing semantic variance
            </button>
            <span className="project-line__bg"></span>
          </span>
        </div>
      </div>
      <ProjectModal
        isVisible={isModalOpen}
        project={project}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default Projects;
